<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(housingComplexId)"
            :state="state2">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="housingComplexId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Дом"
            label-for="building"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(buildingId)"
            :state="state4">
            <b-form-select id="building"
                           :options="building"
                           placeholder="Выберите дом"
                           v-model="buildingId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите здание</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Квартира"
            label-for="premise"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.premiseId)"
            :state="state9">
            <b-form-select id="premise"
                           :options="premise"
                           placeholder="Выберите квартиру"
                           v-model="model.premiseId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите квартиру</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-card>
        <b-card>
          <template #header>
            <strong>Информация о счётчике</strong>
          </template>
          <b-form-group
            label="Автоматический счётчик"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isAuto" class="switch-input" checked>
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Тип счётчика"
            label-for="status"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.meterTypeId)"
            :state="state1">
            <b-form-select id="status"
                           :options="meterType"
                           v-model="model.meterTypeId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите тип счётчика</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер счётчика"
            label-for="meterNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.number)"
            :state="state5">
            <b-form-input id="meterNumber" type="text" :state="state5" v-model="model.number"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер модема"
            label-for="modemNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.emptyProvided(model.modemNumber, model.isAuto)"
            :state="state6">
            <b-form-input id="modemNumber" type="text" :state="state6" v-model="model.modemNumber"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Модель счётчика"
            label-for="meterModel"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="meterModel" type="text" v-model="model.model"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="getMeterTypeKey === 'electro'"
            label="Количество тарифов"
            label-for="tarCount"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="tarCount" type="text" v-model="model.tarCount"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Дата установки"
            label-for="installationDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.installationDate)"
            :state="state7">
            <datetime type="datetime"
              id="installationDate"
              v-model="date.installationDate"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Укажите дату установки счётчика"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
              :state="state7">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Дата последнего обновления"
            label-for="lastTimestamp"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.lastTimestamp)"
            :state="state8">
            <datetime type="datetime"
              id="lastTimestamp"
              v-model="date.lastTimestamp"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Укажите дату последнего обновления"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}"
              v-bind:class="{invalid__dateTime: !state8, valid__dateTime: state8 === true}"
              :state="state8">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Дата следующей поверки"
            label-for="nextLastTimestamp"
            :label-cols="3"
            :horizontal="true">
            <datetime type="datetime"
              id="nextLastTimestamp"
              v-model="date.verificDate"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Укажите дату последней поверки"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Номер пломбы"
            label-for="sealNumber"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="sealNumber" type="text" v-model="model.sealNumber"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Привязан к квартире"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary" @click="untied()">
              <input type="checkbox" v-model="model.isTied" class="switch-input" checked>
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            v-if="!model.isTied"
            label="Дата отвязки"
            label-for="verificDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateUntiedSelect(date.untiedDate, !model.isTied)"
            :state="state10">
            <datetime type="datetime"
              id="verificDate"
              v-model="date.untiedDate"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Укажите дату последней поверки"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}"
              v-bind:class="{invalid__dateTime: !state10, valid__dateTime: state10}"
              :state="state10">
            </datetime>
          </b-form-group>
        </b-card>
        <b-card v-if="model.isAuto">
          <template #header>
            <strong>Автоматические показания</strong>
          </template>
          <b-form-group
            label="Регистратор"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button v-for="i in model.registrators" @click="changeRegistrator(i.name, i.id)" variant="info" :pressed="focusRegistrator === i.name">{{i.name}}</b-button>
            </b-button-group>
          </b-form-group>
          <b-form-group
            label="Временной промежуток (автоматические счётчики)"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button @click="changeType('hour')" variant="info" :pressed="pickerType === 'day'">По часам</b-button>
              <b-button @click="changeType('day')" variant="info" :pressed="pickerType === 'month'">По дням</b-button>
              <b-button @click="changeType('month')" variant="info" :pressed="pickerType === 'year'">По месяцам</b-button>
            </b-button-group>
            <datepicker :minimumView="pickerType" :maximumView="pickerType" :initialView="pickerType" v-model="date.history" :format="customFormatter" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
          </b-form-group>
          <b-form-group
            v-if="model.isAuto"
            label="Автоматические показания"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields">
            </b-table>
          </b-form-group>
        </b-card>
        <b-card>
          <template #header>
            <strong>Переданные показания</strong>
          </template>
          <b-form-group
            label="Регистратор"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button v-for="i in model.registrators" @click="changeRegistratorHand(i.name, i.id)" variant="info" :pressed="focusRegistratorHand === i.name">{{i.name}}</b-button>
            </b-button-group>
          </b-form-group>
          <b-form-group
            label="Временной промежуток (ручные счётчики)"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button variant="info" :pressed="true">По месяцам</b-button>
            </b-button-group>
            <datepicker :minimumView="'year'" :maximumView="'year'" :initialView="'year'" v-model="date.handsHistory" :format="customFormatterFormHands" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
          </b-form-group>
          <b-form-group
            label="Ручные показания"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="handItems" :fields="handsFields">
            </b-table>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Счётчик успешно сохранен">
      <p class="my-4">Счётчик успешно изменён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import validation from '../../config/validation'

  export default {
    name: 'Item',
    components: {
      loading,
      datetime: Datetime,
      Datepicker
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        meterId: this.$route.params.id,
        model: {
          meterTypeId: 0,
          premiseId: 0,
          number: '',
          modemNumber: '',
          model: '',
          isDemo: false,
          sealNumber: '',
          isAuto: true,
          isTied: true
        },
        pickerType: 'day',
        mrId: 0,
        mrIdHand: 0,
        meterType: [],
        handItems: [],
        items: [],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        premise: [
        ],
        accessList: {},
        housingComplexId: '',
        streetId: '',
        buildingId: '',
        premiseId: '',
        fields: [
          {key: 'timestamp', label: 'Дата'},
          {key: 'begin', label: 'Начало'},
          {key: 'consumption', label: 'Расход'},
          {key: 'end', label: 'Конец'}
        ],
        handsFields: [
          {key: 'timestamp', label: 'Дата'},
          {key: 'measurement', label: 'Показание'}
        ],
        date: {
          installationDate: '',
          lastTimestamp: '',
          history: moment().valueOf(),
          handsHistory: moment().valueOf(),
          verificDate: '',
          untiedDate: ''
        },
        urls: {
          meter: 'meter',
          metertype: 'metertype',
          premiseAddress: 'premise/premiseaddress',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise',
          list: 'measurementhistory',
          handList: 'measurement/list'
        },
        firstLoadForm: true,
        show: false,
        hComplex: '',
        hComplexId: 0,
        focusRegistrator: '',
        focusRegistratorHand: '',
        idRegistrator: '',
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    },
    computed: {
      state1 () {
        return validation.numId(this.model.meterTypeId)
      },
      state2 () {
        return validation.numId(this.housingComplexId)
      },
      // state3 () {
      //   return validation.numId(this.streetId)
      // },
      state4 () {
        return validation.numId(this.buildingId)
      },
      state9 () {
        return validation.numId(this.model.premiseId)
      },
      state5 () {
        return validation.long(this.model.number)
      },
      state6 () {
        return validation.longProvided(this.model.modemNumber, this.model.isAuto)
      },
      state7 () {
        return validation.dateSelectBool(this.date.installationDate)
      },
      state8 () {
        return validation.dateSelectBool(this.date.lastTimestamp)
      },
      state10 () {
        return validation.dateUntiedSelectBool(this.date.untiedDate, !this.model.isTied)
      },
      getMeterTypeKey () {
        let findItem = this.meterType.find(item => item.value === this.model.meterTypeId)
        if (findItem) {
          return findItem.key
        } else {
          return ''
        }
      },
      pageTitle () {
        return this.meterId ? 'Редактирование счетчика' : 'Создание счетчика'
      }
    },
    methods: {
      changeRegistrator (registrator, id) {
        let _this = this
        _this.mrId = id
        _this.focusRegistrator = registrator
      },
      changeRegistratorHand (registrator, id) {
        let _this = this
        _this.mrIdHand = id
        _this.focusRegistratorHand = registrator
      },
      customFormatter (date) {
        let _this = this
        moment.locale('ru')
        if (_this.pickerType === 'day') {
          return moment(date).format('DD.MM.YYYY')
        } else if (_this.pickerType === 'month') {
          return moment(date).format('MM.YYYY')
        } else if (_this.pickerType === 'year') {
          return moment(date).format('YYYY')
        }
      },
      customFormatterFormHands (date) {
        moment.locale('ru')
        return moment(date).format('YYYY')
      },
      changeType (key) {
        let _this = this
        switch (key) {
          case 'hour':
            _this.pickerType = 'day'
            break
          case 'day':
            _this.pickerType = 'month'
            break
          case 'month':
            _this.pickerType = 'year'
            break
        }
      },
      whenTabInFocus () {
        let _this = this
        let query = {
          id: _this.model.premiseId
        }
        api.get(_this.urls.premiseAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hComplexId = jData.data.housingComplexId
          }
          return api.get(_this.urls.housingComplex)
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.hComplexId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      getTypes () {
        let _this = this
        api.get(_this.urls.metertype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              temp.key = spec.keyWord
              _this.meterType.push(temp)
            })
          }
        })
      },
      getPremiseAddress () {
        let _this = this
        let query = {
          id: _this.model.premiseId
        }
        api.get(_this.urls.premiseAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.housingComplexId = jData.data.housingComplexId
            _this.streetId = jData.data.streetId
            _this.buildingId = jData.data.buildingId
            _this.premiseId = jData.data.premiseId
          }
        }).then(() => {
          _this.getHousingComplex()
        })
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          _this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this

        if (_this.housingComplexId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.housingComplexId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        let _this = this
        let query = {
          buildingId: _this.buildingId
        }
        api.get(_this.urls.premise, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.premise = []
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.premiseNum
              _this.premise.push(temp)
            })
          }
        }).then(() => {
          _this.firstLoadForm = false
        })
      },
      getHistory () {
        let _this = this
        let query = {
          mrId: _this.mrId,
          timestamp: moment(_this.date.history).format('X'),
          limits: _this.pickerType.toUpperCase()
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jData = response.data
          _this.items = jData.data
          if (_this.pickerType === 'day') {
            _this.items.forEach((item) => {
              item.timestamp = moment(item.timestamp * 1000).utc().format('HH:mm')
            })
          } else if (_this.pickerType === 'month') {
            _this.items.forEach((item) => {
              item.timestamp = moment(item.timestamp * 1000).utc().format('D MMMM')
            })
          } else if (_this.pickerType === 'year') {
            _this.items.forEach((item) => {
              item.timestamp = moment(item.timestamp * 1000).utc().format('MMMM')
            })
          }
        })
      },
      getHandHistory () {
        let _this = this
        let query = {
          mrId: _this.mrIdHand,
          timestamp: moment(_this.date.handHistory).format('X'),
          limit: 'YEAR'
        }
        api.get(_this.urls.handList, query).then(function (response) {
          let jData = response.data
          _this.handItems = jData.data
          _this.handItems.forEach((item) => {
            item.timestamp = moment(item.timestamp * 1000).format('MMMM')
          })
        })
      },
      goBack () {
        this.$router.go(-1)
      },
      untied () {
        if (this.model.isTied) {
          this.date.untiedDate = ''
        }
      },
      sendForm () {
        let _this = this
        if (_this.state9 && _this.state1 && _this.state2 && _this.state4 &&
        _this.state5 && (_this.state6 || !this.model.isAuto) &&
        _this.state7 && _this.state8 && (_this.state10 || _this.model.isTied)) {
          _this.whenTabInFocus()
          _this.show = true
          _this.model.installationDate = moment(_this.date.installationDate).format('X')
          _this.model.lastTimestamp = moment(_this.date.lastTimestamp).format('X')
          if (_this.date.verificDate !== '') {
            _this.model.verificDate = moment(_this.date.verificDate).format('X')
          }
          if (_this.date.untiedDate !== '') {
            _this.model.untiedDate = moment(_this.date.untiedDate).format('X')
          }

          let promise = null

          if (this.meterId) {
            promise = api.put(_this.urls.meter + '/' + _this.$route.params.id, _this.model)
          } else {
            promise = api.post(_this.urls.meter, _this.model)
          }

          promise.then(function (response) {
            _this.show = false
            if (response.status) {
              if (response.data.status) {
                _this.$refs.myModalRef.show()
                setTimeout(function () {
                  _this.$refs.myModalRef.hide()
                  _this.$router.push({ path: '/meterroom/list', query: { tab: _this.hComplex, currentPage: _this.$route.query.currentPage } })
                }, 1000)
              } else {
                alert('Ошибка, проверьте введеные данные и попробуйте снова')
              }
            }
          })
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'meter'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getTypes()
      if (this.meterId) {
        api.get(_this.urls.meter + '/' + _this.$route.params.id).then(function (response) {
          let jData = response.data
          _this.model = jData.data
          _this.date.lastTimestamp = moment(_this.model.lastTimestamp * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
          _this.date.installationDate = moment(_this.model.installationDate * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
          _this.date.untiedDate = _this.model.untiedDate === null ? '' : moment(_this.model.untiedDate * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
          if (_this.model.registrators.length > 0) {
            _this.focusRegistrator = _this.model.registrators[0].name
            _this.focusRegistratorHand = _this.model.registrators[0].name
            _this.mrId = _this.model.registrators[0].id
            _this.mrIdHand = _this.model.registrators[0].id
          }
          if (_this.model.verificDate !== null) {
            _this.date.verificDate = moment(_this.model.verificDate * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
          }
        }).then(() => {
          _this.getPremiseAddress()
          _this.getHistory()
          _this.getHandHistory()
        })
      } else {
        _this.getHousingComplex()
      }
    },
    watch: {
      'date.history': function () {
        let _this = this
        _this.getHistory()
      },
      'date.handsHistory': function () {
        let _this = this
        _this.getHandHistory()
      },
      focusRegistrator: function () {
        let _this = this
        _this.getHistory()
      },
      focusRegistratorHand: function () {
        let _this = this
        _this.getHandHistory()
      },
      housingComplexId: function () {
        this.street = []
        this.building = []
        this.premise = []
        this.accommodation = []
        if (!this.firstLoadForm) {
          this.buildingId = 0
          this.model.premiseId = 0
        }
        this.getStreet()
      },
      buildingId: function () {
        this.premise = []
        this.accommodation = []
        if (!this.firstLoadForm) {
          this.model.premiseId = 0
        }
        this.getPremise()
      }
    }
  }
</script>
