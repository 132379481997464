import constants from './const'
import vueCookie from 'vue-cookie'
import axios from 'axios'

let clientGuid = ''
if (vueCookie.get('guid')) {
  clientGuid = vueCookie.get('guid')
}

let api = {}
api.get = function (url, params = {}, headers = {}) {
  let token = vueCookie.get('authToken')
  let esc = encodeURIComponent
  let query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&')
  if (token !== null) {
    headers.Authorization = token
  }
  if (clientGuid) {
    headers['x-client-guid'] = clientGuid
  }
  return axios.get(constants.apiBaseUrl + url + '?' + query, {
    headers: headers
  })
}
api.post = function (url, params = {}, headers = {}) {
  let token = vueCookie.get('authToken')
  if (token !== null) {
    headers.Authorization = token
  }
  if (clientGuid) {
    headers['x-client-guid'] = clientGuid
  }
  // params.headers = headers
  return axios.post(constants.apiBaseUrl + url, params, {
    headers: headers,
    withCredentials: false
  })
}
api.put = function (url, params = {}, headers = {}) {
  let token = vueCookie.get('authToken')
  if (token !== null) {
    headers.Authorization = token
  }
  if (clientGuid) {
    headers['x-client-guid'] = clientGuid
  }
  return axios.put(constants.apiBaseUrl + url + '?', params, {
    headers: headers
  })
}
api.delete = function (url, params = {}, headers = {}) {
  let token = vueCookie.get('authToken')
  if (token !== null) {
    headers.Authorization = token
  }
  if (clientGuid) {
    headers['x-client-guid'] = clientGuid
  }
  params.headers = headers
  return axios.delete(constants.apiBaseUrl + url + '?', params)
}
export default api
