const domain = process.env.VUE_APP_DOMAIN;

const config = {
  BASE_URL: process.env.BASE_URL,
  NODE_ENV: 'production',
  apiBaseUrl: domain + '/v2/',
  storageUrl: domain,
  templatePremise: 'files/templates/kvartira_shablon.xlsx'
}

export default config; 