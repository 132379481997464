export default {
  items: [
    {
      name: 'Рабочий стол',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: 'Заявки',
      name: 'Заявки',
      keyWord: 'menuDelimeter',
      keyArray: ['serviceRequest', 'requestType', 'requestNat', 'status', 'category', 'subcategory']
    },
    {
      name: 'Список заявок',
      url: '/service_requests/list',
      icon: 'icon-layers',
      class: 'requests',
      badge: {
        variant: 'primary',
        text: '0'
      },
      keyWord: 'serviceRequest'
    },
    // {
    //   name: 'Типы заявок',
    //   url: '/service_requests/types/list',
    //   icon: 'icon-tag',
    //   keyWord: 'requestType'
    // },
    // {
    //   name: 'Характеры заявок',
    //   url: '/service_requests/requestnature/list',
    //   icon: 'icon-bell',
    //   keyWord: 'requestNat'
    // },
    // {
    //   name: 'Статусы заявок',
    //   url: '/service_requests/status/list',
    //   icon: 'icon-hourglass',
    //   keyWord: 'status'
    // },
    {
      name: 'Категории заявок',
      url: '/service_requests/categories/list',
      icon: 'icon-folder',
      keyWord: 'category'
    },
    {
      name: 'Подкатегории заявок',
      url: '/service_requests/subcategories/list',
      icon: 'icon-folder-alt',
      keyWord: 'subcategory'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['serviceRequest', 'requestType', 'requestNat', 'status', 'category', 'subcategory']
    },
    {
      title: 'Переписка',
      name: 'Переписка',
      keyWord: 'menuDelimeter',
      keyArray: ['chat']
    },
    {
      name: 'Чаты',
      class: 'chat',
      url: '/chats/list',
      icon: 'icon-bubbles',
      badge: {
        variant: 'primary',
        text: '0'
      },
      keyWord: 'chat'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['chat']
    },
    {
      title: 'Пользователи',
      name: 'Пользователи',
      keyWord: 'menuDelimeter',
      keyArray: ['user', 'admins', 'specialist', 'company', 'contractor', 'post', 'userRole']
    },
    // {
    //   name: 'Сотрудники',
    //   url: '/employee/list',
    //   icon: 'icon-user-following',
    //   keyWord: 'admins'
    // },
    // {
    //   name: 'Специалисты',
    //   url: '/specialist/list',
    //   icon: 'icon-user-following',
    //   keyWord: 'specialist'
    // },
    // {
    //   name: 'Подрядчики',
    //   url: '/contractor/list',
    //   icon: 'icon-wrench',
    //   keyWord: 'contractor'
    // },
    // {
    //   name: 'Компании',
    //   url: '/company/list',
    //   icon: 'icon-briefcase',
    //   keyWord: 'company'
    // },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['user', 'admins', 'specialist', 'company', 'contractor', 'post', 'userRole']
    },
    {
      title: 'Счётчики',
      name: 'Счётчики',
      keyWord: 'menuDelimeter',
      keyArray: ['meterHouse', 'meterRoom', 'heating', 'socket']
    },
    {
      name: 'Общедомовые',
      url: '/meterHouse/list',
      icon: 'fa fa-building-o',
      keyWord: 'meterHouse'
    },
    {
      name: 'Квартирные',
      url: '/meterRoom/list',
      icon: 'fa fa-bed',
      keyWord: 'meter'
    },
    {
      name: 'Управление отоплением',
      url: '/heating/list',
      icon: 'fa fa-thermometer-half',
      keyWord: 'heating'
    },
    {
      name: 'Управление розетками',
      url: '/socket/list',
      icon: 'fa fa-plug',
      keyWord: 'socket'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['meterHouse', 'meterRoom', 'heating', 'socket']
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['news', 'notification', 'poll']
    },
    {
      title: 'Видеонаблюдение',
      name: 'Видеонаблюдение',
      keyWord: 'menuDelimeter',
      keyArray: ['camera', 'barrier', 'intercom']
    },
    // {
    //   name: 'Камеры',
    //   url: '/cameras/list',
    //   icon: 'icon-camrecorder',
    //   keyWord: 'camera'
    // },
    {
      name: 'Точки доступа',
      url: '/barrier/list',
      icon: 'fa fa-exchange',
      keyWord: 'barrier'
    },
    {
      name: 'Домофоны',
      url: '/intercom/list',
      icon: 'icon-call-in',
      keyWord: 'intercom'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['camera', 'barrier', 'intercom']
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['complexInfo', 'emergencyService']
    },
    {
      title: 'Объекты',
      name: 'Объекты',
      keyWord: 'menuDelimeter',
      keyArray: ['housingComplex', 'street', 'building', 'premise']
    },
    {
      name: 'ЖК',
      url: '/housingcomplex/list',
      icon: 'icon-grid',
      keyWord: 'housingComplex'
    },
    {
      name: 'Улицы',
      url: '/street/list',
      icon: 'icon-direction',
      keyWord: 'street'
    },
    {
      name: 'Квартиры',
      url: '/premise/list',
      icon: 'fa fa-bed',
      keyWord: 'premise'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['housingComplex', 'street', 'building', 'premise']
    },
    {
      title: 'ГИС ЖКХ',
      name: 'ГИС ЖКХ',
      keyWord: 'menuDelimeter',
      keyArray: ['payment']
    },
    {
      name: 'Платёжные документы',
      url: '/paymentdocument/list',
      icon: 'fa fa-product-hunt',
      keyWord: 'payment'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['payment']
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['car']
    },
    {
      title: 'Справочники',
      name: 'Справочники',
      keyWord: 'menuDelimeter',
      keyArray: ['city', 'premiseType', 'chatType', 'contactType', 'networkType', 'meterType', 'contentPage']
    },
    {
      name: 'Список городов',
      url: '/city/list',
      icon: 'icon-map',
      keyWord: 'city'
    },
    // {
    //   name: 'Типы помещений',
    //   url: '/premisetype/list',
    //   icon: 'icon-tag',
    //   keyWord: 'premiseType'
    // },
    // {
    //   name: 'Типы чатов',
    //   url: '/chattype/list',
    //   icon: 'icon-tag',
    //   keyWord: 'chatType'
    // },
    {
      name: 'Типы счётчиков',
      url: '/metertype/list',
      icon: 'icon-tag',
      keyWord: 'meterType'
    },
    {
      name: 'Типы комнат',
      url: '/roomtype/list',
      icon: 'icon-tag',
      keyWord: 'roomType'
    },
    {
      name: 'Наименования комнат',
      url: '/roomname/list',
      icon: 'icon-tag',
      keyWord: 'roomName'
    },
    {
      name: 'Контентные страницы',
      url: '/contentpage/list',
      icon: 'icon-doc',
      keyWord: 'contentPage'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['premiseType', 'chatType', 'contactType', 'networkType', 'meterType', 'contentPage']
    },
    {
      title: 'Версии',
      name: 'Версии',
      keyWord: 'menuDelimeter',
      keyArray: ['version']
    },
    {
      name: 'История',
      url: '/version/list',
      icon: 'icon-globe',
      keyWord: 'version'
    },
    {
      name: 'Настройка',
      url: '/version/options',
      icon: 'icon-settings',
      keyWord: 'version'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['version']
    },
    {
      title: 'Алерты',
      name: 'Алерты',
      keyWord: 'menuDelimeter',
      keyArray: ['technicalServices']
    },
    // {
    //   name: 'История',
    //   url: '/technicalservices/list',
    //   icon: 'icon-globe',
    //   keyWord: 'technicalservices'
    // },
    {
      name: 'Настройка',
      url: '/technicalservices/options',
      icon: 'icon-settings',
      keyWord: 'technicalServices'
    },
    {
      title: 'Алерты',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['technicalServices']
    },
    {
      title: 'Импорт',
      name: 'Импорт',
      keyWord: 'menuDelimeter',
      keyArray: ['import']
    },
    {
      name: 'Импорт квартир',
      url: '/import/premise',
      icon: 'icon-action-undo',
      keyWord: 'import'
    },
    {
      name: 'Импорт счётчиков',
      url: '/import/meter',
      icon: 'icon-action-undo',
      keyWord: 'import'
    },
    {
      title: 'Импорт',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['import']
    },
    {
      name: 'Настройка всей лид-формы',
      url: '/leadFormAll/edit',
      icon: 'icon-puzzle',
      keyWord: 'leadForm'
    },
    {
      name: 'Заявки на просмотр квартиры',
      url: '/leadRequest/list',
      icon: 'icon-info',
      keyWord: 'leadRequest'
    },
    {
      name: 'Настройка E-mail',
      url: '/emailForm/edit',
      icon: 'icon-envelope',
      keyWord: 'mail'
    },
    {
      name: 'Настройки крон',
      url: '/cron/list',
      icon: 'icon-settings',
      keyWord: 'cron'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['leadForm', 'mail', 'leadRequest', 'cron']
    },
    {
      title: 'Отчёты',
      name: 'Отчёты',
      keyWord: 'menuDelimeter',
      keyArray: ['report']
    },
    {
      name: 'Все счётчики',
      url: '/reportMeter/list',
      icon: 'fa fa-calendar',
      keyWord: 'report'
    },
    {
      name: 'Передача показаний',
      url: '/reportMeasurement/list',
      icon: 'fa fa-calendar',
      keyWord: 'report'
    },
    {
      name: 'Квартирные счётчики',
      url: '/report/list',
      icon: 'fa fa-bed',
      keyWord: 'report'
    },
    {
      name: 'Общедомовые счётчики',
      url: '/reportHouse/list',
      icon: 'fa fa-building-o',
      keyWord: 'report'
    },
    {
      title: 'Заявки',
      name: '',
      keyWord: 'menuDelimeter',
      keyArray: ['report']
    }
  ]
}
