// Containers
import Full from '@/containers/Full'

// Views
import Dashboard from '@/views/Dashboard'

import Colors from '@/views/theme/Colors'
import Typography from '@/views/theme/Typography'

// Views - Base
import Cards from '@/views/base/Cards'
import Switches from '@/views/base/Switches'
import Breadcrumbs from '@/views/base/Breadcrumbs'
import Carousels from '@/views/base/Carousels'
import Collapses from '@/views/base/Collapses'
import Jumbotrons from '@/views/base/Jumbotrons'
import ListGroups from '@/views/base/ListGroups'
import Navs from '@/views/base/Navs'
import Navbars from '@/views/base/Navbars'
import Paginations from '@/views/base/Paginations'
import Popovers from '@/views/base/Popovers'
import ProgressBars from '@/views/base/ProgressBars'
import Tooltips from '@/views/base/Tooltips'

// Views - Buttons
import StandardButtons from '@/views/buttons/StandardButtons'
import ButtonGroups from '@/views/buttons/ButtonGroups'
import Dropdowns from '@/views/buttons/Dropdowns'
import SocialButtons from '@/views/buttons/SocialButtons'

import Charts from '@/views/Charts'

// Views - Editors
import TextEditors from '@/views/editors/TextEditors'
import CodeEditors from '@/views/editors/CodeEditors'

// Views - Forms
import BasicForms from '@/views/forms/BasicForms'
import AdvancedForms from '@/views/forms/AdvancedForms'

import GoogleMaps from '@/views/GoogleMaps'

// Views - Icons
import Flags from '@/views/icons/Flags'
import FontAwesome from '@/views/icons/FontAwesome'
import SimpleLineIcons from '@/views/icons/SimpleLineIcons'

// Views - Notifications
import Alerts from '@/views/notifications/Alerts'
import Badges from '@/views/notifications/Badges'
import Modals from '@/views/notifications/Modals'
import Toastr from '@/views/notifications/Toastr'

import Tables from '@/views/tables/Tables'

import Widgets from '@/views/Widgets'

// Views - Pages
import Page403 from '@/views/pages/Page403'
import Page404 from '@/views/pages/Page404'
import Page500 from '@/views/pages/Page500'
import Login from '@/views/pages/Login'
import LoginContractor from '@/views/pages/LoginContractor'
import Register from '@/views/pages/Register'

// Views - UI Kits
import ServiceRequestsList from '@/views/service_requests/List'
import ServiceRequestsNew from '@/views/service_requests/New'
import ServiceRequestsEdit from '@/views/service_requests/Edit'
import ServiceRequestsLog from '@/views/service_requests/Log'

import ServiceRequestsContractorList from '@/views/pages/service_requests/List'
import ServiceRequestsContractorEdit from '@/views/pages/service_requests/Edit'
import ServiceRequestsContractorChat from '@/views/pages/service_requests/Chat'

import NewsList from '@/views/news/List'
import NewsNew from '@/views/news/New'
import NewsEdit from '@/views/news/Edit'

import NoticeList from '@/views/notice/List'
import NoticeNew from '@/views/notice/New'
import NoticeEdit from '@/views/notice/Edit'

import PollList from '@/views/poll/List'
import PollNew from '@/views/poll/New'
import PollEdit from '@/views/poll/Edit'
import PollStat from '@/views/poll/Stat'
import PollAnswer from '@/views/poll/Answers'

import RequestNatureList from '@/views/service_requests/requestnature/List'
import RequestNatureNew from '@/views/service_requests/requestnature/New'
import RequestNatureEdit from '@/views/service_requests/requestnature/Edit'

import StreetList from '@/views/street/List'
import StreetNew from '@/views/street/New'
import StreetEdit from '@/views/street/Edit'

import PostList from '@/views/post/List'
import PostNew from '@/views/post/New'
import PostEdit from '@/views/post/Edit'

import PrivacyAccountList from '@/views/paymentdocument/List'
import PaymentDocumentList from '@/views/paymentdocument/documentList'
import PaymentDocumentDetail from '@/views/paymentdocument/detailList'

import NetworkTypeList from '@/views/networktype/List'
// import NetworkTypeNew from '@/views/networktype/New'
import NetworkTypeEdit from '@/views/networktype/Edit'

import RoomTypeList from '@/views/roomType/List'
import RoomTypeEdit from '@/views/roomType/Edit'

import CityList from '@/views/city/List'
import CityItem from '@/views/city/Item'

import RoomNameList from '@/views/roomName/List'
import RoomNameNew from '@/views/roomName/New'
import RoomNameEdit from '@/views/roomName/Edit'

import CarList from '@/views/car/List'
import CarNew from '@/views/car/New'
import CarEdit from '@/views/car/Edit'

import IntercomList from '@/views/intercom/List'
import IntercomItem from '@/views/intercom/Item'

import BuildingList from '@/views/building/List'
import BuildingItem from '@/views/building/Item'

import PremiseList from '@/views/premise/List'
import PremiseItem from '@/views/premise/Item'

import CamerasList from '@/views/cameras/List'
import CamerasItem from '@/views/cameras/Item'

import BarrierList from '@/views/barrier/List'
import BarrierItem from '@/views/barrier/Item'

import ContentPageList from '@/views/contentpage/List'
import ContentPageNew from '@/views/contentpage/New'
import ContentPageEdit from '@/views/contentpage/Edit'

import MeterTypeList from '@/views/metertype/List'
// import MeterTypeNew from '@/views/metertype/New'
import MeterTypeEdit from '@/views/metertype/Edit'

import PremiseTypeList from '@/views/premisetype/List'
// import PremiseTypeNew from '@/views/premisetype/New'
import PremiseTypeEdit from '@/views/premisetype/Edit'

import ReportList from '@/views/report/List'
import ReportMeasurementList from '@/views/reportMeasurement/List'
import ReportHouseList from '@/views/reportHouse/List'
import ReportMeterList from '@/views/reportMeter/List'

import EmergencyServiceList from '@/views/emergencyservice/List'
import EmergencyServiceNew from '@/views/emergencyservice/New'
import EmergencyServiceEdit from '@/views/emergencyservice/Edit'

import HousingComplexList from '@/views/housingcomplex/List'
import HousingComplexItem from '@/views/housingcomplex/Item'

import ComplexInfoList from '@/views/complexinfo/List'
import ComplexInfoNew from '@/views/complexinfo/New'
import ComplexInfoEdit from '@/views/complexinfo/Edit'

import ChatList from '@/views/chats/List'
import ChatItem from '@/views/chats/Item'
import ChatView from '@/views/chats/Chat'
import ChatUserList from '@/views/chats/UserList'

import MeterHouseList from '@/views/meterHouse/List'
import MeterHouseNew from '@/views/meterHouse/New'
import MeterHouseEdit from '@/views/meterHouse/Edit'

import MeterRoomList from '@/views/meterRoom/List'
import MeterRoomItem from '@/views/meterRoom/Item'

import ChatTypeList from '@/views/chattype/List'
// import ChatTypeNew from '@/views/chattype/New'
import ChatTypeEdit from '@/views/chattype/Edit'

import SubcategoriesList from '@/views/service_requests/subcategories/List'
import SubcategoriesNew from '@/views/service_requests/subcategories/New'
import SubcategoriesEdit from '@/views/service_requests/subcategories/Edit'

import ServiceRequestsTypesList from '@/views/service_requests/types/List'
// import ServiceRequestsTypesNew from '@/views/service_requests/types/New'
import ServiceRequestsTypesEdit from '@/views/service_requests/types/Edit'

import ServiceRequestsStatusList from '@/views/service_requests/status/List'
// import ServiceRequestsStatusNew from '@/views/service_requests/status/New'
import ServiceRequestsStatusEdit from '@/views/service_requests/status/Edit'

import ServiceRequestsCategoriesList from '@/views/service_requests/categories/List'
import ServiceRequestsCategoriesItem from '@/views/service_requests/categories/Item'

import UserList from '@/views/user/List'
import UserNew from '@/views/user/New'
import UserEdit from '@/views/user/Edit'

import EmployeeList from '@/views/employee/List'
import EmployeeNew from '@/views/employee/New'
import EmployeeEdit from '@/views/employee/Edit'
import EmployeeLoginLog from '@/views/employee/LoginLog'

import SpecialistList from '@/views/specialist/List'
import SpecialistNew from '@/views/specialist/New'
import SpecialistEdit from '@/views/specialist/Edit'

import ContractorList from '@/views/contractor/List'
import ContractorNew from '@/views/contractor/New'
import ContractorEdit from '@/views/contractor/Edit'

import CompanyList from '@/views/company/List'
import CompanyNew from '@/views/company/New'
import CompanyEdit from '@/views/company/Edit'

import RoleList from '@/views/role/List'
import RoleNew from '@/views/role/New'
import RoleEdit from '@/views/role/Edit'

import LeadRequestList from '@/views/leadRequest/List'
import LeadRequestEdit from '@/views/leadRequest/Edit'

import CronList from '@/views/cron/List'
import CronEdit from '@/views/cron/Edit'

import ImportPremise from '@/views/import/premise'
import ImportMeter from '@/views/import/meter'

import HeatingList from '@/views/heating/List'
import HeatingNew from '@/views/heating/New'
import HeatingEdit from '@/views/heating/Edit'

import SocketList from '@/views/socket/List'
import SocketNew from '@/views/socket/New'
import SocketEdit from '@/views/socket/Edit'

import VersionsList from '@/views/version/List'
import VersionsNew from '@/views/version/New'
import VersionsEdit from '@/views/version/Edit'
import VersionsOptions from '@/views/version/Options'

import TechnicalServicesList from '@/views/technicalServices/List'
import TechnicalServicesNew from '@/views/technicalServices/New'
import TechnicalServicesEdit from '@/views/technicalServices/Edit'
import TechnicalServicesOptions from '@/views/technicalServices/Options'

import LeadFormAllEdit from '@/views/leadFormAll/Edit'
// import LeadFormEdit from '@/views/leadForm/Edit'
// import LeadFormFieldEdit from '@/views/leadFormField/Edit'
import RequestFrequencyEdit from '@/views/requestFrequency/Edit'
import MailFormEdit from '@/views/emailForm/Edit'

// import GIS from '@/views/GIS'
import PaymentExecutorList from '@/views/paymentExecutor/List'
import PaymentExecutorEdit from '@/views/paymentExecutor/Edit'
import PaymentRecipientList from '@/views/paymentRecipient/List'
import PaymentRecipientEdit from '@/views/paymentRecipient/Edit'

export default [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Главная',
    component: Full,
    children: [
      {
        path: 'dashboard',
        name: 'Рабочий стол',
        component: Dashboard
      },
      {
        path: '/service_requests',
        redirect: '/service_requests/list',
        name: 'Заявки',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все заявки',
            component: ServiceRequestsList
          },
          {
            path: 'new',
            name: 'Добавить заявку',
            component: ServiceRequestsNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить заявку',
            component: ServiceRequestsEdit
          },
          {
            path: 'log/:id',
            name: 'История изменений заявки',
            component: ServiceRequestsLog
          },
          {
            path: 'chat/:id',
            name: 'Войти в чат',
            component: ChatView
          },
          {
            path: 'requestnature',
            redirect: '/service_requests/requestnature/list',
            name: 'Характеры заявок',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'list',
                name: 'Все характеры заявок',
                component: RequestNatureList
              },
              {
                path: 'new',
                name: 'Добавить характер заявки',
                component: RequestNatureNew
              },
              {
                path: 'edit/:id',
                name: 'Изменить характер заявки',
                component: RequestNatureEdit
              }
            ]
          },
          {
            path: 'subcategories',
            redirect: '/service_requests/subcategories/list',
            name: 'Подкатегории',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'list',
                name: 'Все подкатегории',
                component: SubcategoriesList
              },
              {
                path: 'new',
                name: 'Добавить подкатегорию',
                component: SubcategoriesNew
              },
              {
                path: 'edit/:id',
                name: 'Изменить подкатегорию',
                component: SubcategoriesEdit
              }
            ]
          },
          {
            path: 'types',
            redirect: '/service_requests/types/list',
            name: 'Типы заявок',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'list',
                name: 'Все типы заявок',
                component: ServiceRequestsTypesList
              },
              // {
              //   path: 'new',
              //   name: 'Добавить тип',
              //   component: ServiceRequestsTypesNew
              // },
              {
                path: 'edit/:id',
                name: 'Изменить тип заявки',
                component: ServiceRequestsTypesEdit
              }
            ]
          },
          {
            path: 'status',
            redirect: '/service_requests/status/list',
            name: 'Статусы',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'list',
                name: 'Все статусы',
                component: ServiceRequestsStatusList
              },
              // {
              //   path: 'new',
              //   name: 'Добавить статус',
              //   component: ServiceRequestsStatusNew
              // },
              {
                path: 'edit/:id',
                name: 'Изменить статус',
                component: ServiceRequestsStatusEdit
              }
            ]
          },
          {
            path: 'categories',
            redirect: '/service_requests/categories/list',
            name: 'Категории',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'list',
                name: 'Все категории',
                component: ServiceRequestsCategoriesList
              },
              {
                path: 'edit/:id',
                name: 'Изменить категорию',
                component: ServiceRequestsCategoriesItem
              },
              {
                path: 'new',
                name: 'Добавить категорию',
                component: ServiceRequestsCategoriesItem
              }
            ]
          }
        ]
      },
      {
        path: '/news',
        redirect: '/news/list',
        name: 'Новости',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все новости',
            component: NewsList
          },
          {
            path: 'new',
            name: 'Добавить новость',
            component: NewsNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить новость',
            component: NewsEdit
          }
        ]
      },
      {
        path: '/meterhouse',
        redirect: '/meterhouse/list',
        name: 'Общедомовые счётчики',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все общедомовые счётчики',
            component: MeterHouseList
          },
          {
            path: 'new',
            name: 'Добавить общедомовой счётчик',
            component: MeterHouseNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить общедомовой счётчик',
            component: MeterHouseEdit
          }
        ]
      },
      {
        path: '/meterroom',
        redirect: '/meterroom/list',
        name: 'Квартирные счётчики',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все квартирные счётчики',
            component: MeterRoomList
          },
          {
            path: 'new',
            name: 'Добавить квартирный счётчик',
            component: MeterRoomItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить квартирный счётчик',
            component: MeterRoomItem
          }
        ]
      },
      {
        path: '/heating',
        redirect: '/heating/list',
        name: 'Управление отоплением',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все устройства',
            component: HeatingList
          },
          {
            path: 'new',
            name: 'Добавить устройство',
            component: HeatingNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить устройство',
            component: HeatingEdit
          }
        ]
      },
      {
        path: '/paymentdocument',
        redirect: '/paymentdocument/list',
        name: 'Лицевые счета',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все лицевые счета',
            component: PrivacyAccountList
          },
          {
            path: 'list/:id',
            name: 'Все платёжные документы',
            component: PaymentDocumentList
          },
          {
            path: 'detail/:id',
            name: 'Детальная информация по платежному документу',
            component: PaymentDocumentDetail
          },
          {
            path: 'detail',
            name: 'Добавить новый документ',
            component: PaymentDocumentDetail
          }
        ]
      },
      {
        path: '/paymentexecutor',
        redirect: '/paymentexecutor/list',
        name: 'Исполнители платежа',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все исполнители',
            component: PaymentExecutorList
          },
          {
            path: 'edit/:id',
            name: 'Изменить исполнителя',
            component: PaymentExecutorEdit
          }
        ]
      },
      {
        path: '/paymentrecipient',
        redirect: '/paymentrecipient/list',
        name: 'Получатели средств',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все получатели',
            component: PaymentRecipientList
          },
          {
            path: 'edit/:id',
            name: 'Изменить получателя',
            component: PaymentRecipientEdit
          }
        ]
      },
      {
        path: '/socket',
        redirect: '/socket/list',
        name: 'Управление розетками',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все устройства',
            component: SocketList
          },
          {
            path: 'new',
            name: 'Добавить устройство',
            component: SocketNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить устройство',
            component: SocketEdit
          }
        ]
      },
      {
        path: '/users',
        redirect: '/users/list',
        name: 'Пользователи',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все пользователи',
            component: UserList
          },
          {
            path: 'new',
            name: 'Добавить пользователя',
            component: UserNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить пользователя',
            component: UserEdit
          }
        ]
      },
      {
        path: '/employee',
        redirect: '/employee/list',
        name: 'Сотрудники',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все сотрудники',
            component: EmployeeList
          },
          {
            path: 'new',
            name: 'Добавить сотрудника',
            component: EmployeeNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить сотрудника',
            component: EmployeeEdit
          },
          {
            path: 'login-log/:id',
            name: 'Рабочие сессии сотрудника',
            component: EmployeeLoginLog
          }
        ]
      },
      // {
      //   path: '/specialist',
      //   redirect: '/specialist/list',
      //   name: 'Специалисты',
      //   component: {
      //     render (c) { return c('router-view') }
      //   },
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'Все специалисты',
      //       component: SpecialistList
      //     },
      //     {
      //       path: 'new',
      //       name: 'Добавить специалиста',
      //       component: SpecialistNew
      //     },
      //     {
      //       path: 'edit/:id',
      //       name: 'Изменить специалиста',
      //       component: SpecialistEdit
      //     }
      //   ]
      // },
      {
        path: '/contractor',
        redirect: '/contractor/list',
        name: 'Подрядчики',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все подрядчики',
            component: ContractorList
          },
          {
            path: 'new',
            name: 'Добавить подрядчика',
            component: ContractorNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить подрядчика',
            component: ContractorEdit
          }
        ]
      },
      {
        path: '/company',
        redirect: '/company/list',
        name: 'Компании',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все компании',
            component: CompanyList
          },
          {
            path: 'new',
            name: 'Добавить компанию',
            component: CompanyNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить компанию',
            component: CompanyEdit
          }
        ]
      },
      {
        path: '/role',
        redirect: '/role/list',
        name: 'Роли',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все роли',
            component: RoleList
          },
          {
            path: 'new',
            name: 'Добавить роль',
            component: RoleNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить роль',
            component: RoleEdit
          }
        ]
      },
      {
        path: '/leadFormAll',
        redirect: '/leadFormAll/edit',
        name: 'Настройка всей лид-формы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'edit',
            name: 'Изменить поля',
            component: LeadFormAllEdit
          }
        ]
      },
      // {
      //   path: '/leadForm',
      //   redirect: '/leadForm/edit',
      //   name: 'Настройка диалогового окна лид-формы',
      //   component: {
      //     render (c) { return c('router-view') }
      //   },
      //   children: [
      //     {
      //       path: 'edit',
      //       name: 'Изменить диалоговое окно',
      //       component: LeadFormEdit
      //     }
      //   ]
      // },
      // {
      //   path: '/leadFormField',
      //   redirect: '/leadFormField/edit',
      //   name: 'Настройка полей лид-формы',
      //   component: {
      //     render (c) { return c('router-view') }
      //   },
      //   children: [
      //     {
      //       path: 'edit',
      //       name: 'Изменить поля',
      //       component: LeadFormFieldEdit
      //     }
      //   ]
      // },
      {
        path: '/requestFrequency',
        redirect: '/requestFrequency/edit',
        name: 'Настройка закрытия заявки',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'edit',
            name: 'Изменить настройку',
            component: RequestFrequencyEdit
          }
        ]
      },
      {
        path: '/emailForm',
        redirect: '/emailForm/edit',
        name: 'Email-форма',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'edit',
            name: 'Настройки E-mail',
            component: MailFormEdit
          }
        ]
      },
      {
        path: '/leadrequest',
        redirect: '/leadrequest/list',
        name: 'Заявки на просмотр квартиры',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все заявки на просмотр квартиры',
            component: LeadRequestList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить точку доступа',
          //   component: BarrierNew
          // },
          {
            path: 'edit/:id',
            name: 'Просмотреть заявку',
            component: LeadRequestEdit
          }
        ]
      },
      {
        path: '/cron',
        redirect: '/cron/list',
        name: 'Настройки крон',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все настройки',
            component: CronList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить точку доступа',
          //   component: BarrierNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить настройки',
            component: CronEdit
          }
        ]
      },
      {
        path: '/import',
        redirect: '/dashboard',
        name: 'Импорт',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'premise',
            name: 'Импорт квартир',
            component: ImportPremise
          },
          // {
          //   path: 'new',
          //   name: 'Добавить точку доступа',
          //   component: BarrierNew
          // },
          {
            path: 'meter',
            name: 'Импорт счётчиков',
            component: ImportMeter
          }
        ]
      },
      {
        path: '/notice',
        redirect: '/notice/list',
        name: 'Уведомления',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все уведомления',
            component: NoticeList
          },
          {
            path: 'new',
            name: 'Добавить уведомлениe',
            component: NoticeNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить уведомлениe',
            component: NoticeEdit
          }
        ]
      },
      {
        path: '/poll',
        redirect: '/poll/list',
        name: 'Опросы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все опросы',
            component: PollList
          },
          {
            path: 'new',
            name: 'Добавить опрос',
            component: PollNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить опрос',
            component: PollEdit
          },
          {
            path: 'stat/:id',
            name: 'Статистика',
            component: PollStat
          },
          {
            path: 'answers/:id',
            name: 'Ответы на опрос',
            component: PollAnswer
          }
        ]
      },
      {
        path: '/housingcomplex',
        redirect: '/housingcomplex/list',
        name: 'ЖК',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все ЖК',
            component: HousingComplexList
          },
          {
            path: 'new',
            name: 'Добавить ЖК',
            component: HousingComplexItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить ЖК',
            component: HousingComplexItem
          }
        ]
      },
      {
        path: '/street',
        redirect: '/street/list',
        name: 'Улицы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все улицы',
            component: StreetList
          },
          {
            path: 'new',
            name: 'Добавить улицу',
            component: StreetNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить улицу',
            component: StreetEdit
          }
        ]
      },
      {
        path: '/post',
        redirect: '/post/list',
        name: 'Должности',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все должности',
            component: PostList
          },
          {
            path: 'new',
            name: 'Добавить должность',
            component: PostNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить должность',
            component: PostEdit
          }
        ]
      },
      {
        path: '/emergencyservice',
        redirect: '/emergencyservice/list',
        name: 'Экстренные службы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все экстренные службы',
            component: EmergencyServiceList
          },
          {
            path: 'new',
            name: 'Добавить экстренную службу',
            component: EmergencyServiceNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить экстренную службу',
            component: EmergencyServiceEdit
          }
        ]
      },
      {
        path: '/car',
        redirect: '/car/list',
        name: 'Автомобили',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все автомобили',
            component: CarList
          },
          {
            path: 'new',
            name: 'Добавить автомобиль',
            component: CarNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить автомобиль',
            component: CarEdit
          }
        ]
      },
      {
        path: '/version',
        redirect: '/version/list',
        name: 'Версии',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все версии',
            component: VersionsList
          },
          {
            path: 'new',
            name: 'Добавить версию',
            component: VersionsNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить версию',
            component: VersionsEdit
          },
          {
            path: 'options',
            name: 'Настройки версии',
            component: VersionsOptions
          }
        ]
      },
      {
        path: '/technicalservices',
        redirect: '/technicalservices/list',
        name: 'Технические работы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все технические работы',
            component: TechnicalServicesList
          },
          {
            path: 'new',
            name: 'Добавить техническую работу',
            component: TechnicalServicesNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить техническую работу',
            component: TechnicalServicesEdit
          },
          {
            path: 'options',
            name: 'Настройки технической работы',
            component: TechnicalServicesOptions
          }
        ]
      },
      {
        path: '/intercom',
        redirect: '/intercom/list',
        name: 'Домофоны',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все домофоны',
            component: IntercomList
          },
          {
            path: 'new',
            name: 'Добавить домофон',
            component: IntercomItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить домофон',
            component: IntercomItem
          }
        ]
      },
      {
        path: '/contentpage',
        redirect: '/contentpage/list',
        name: 'Контентные страницы',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все контентые страницы',
            component: ContentPageList
          },
          {
            path: 'new',
            name: 'Добавить контентую страницу',
            component: ContentPageNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить контентую страницу',
            component: ContentPageEdit
          }
        ]
      },
      {
        path: '/networktype',
        redirect: '/networktype/list',
        name: 'Типы социальных сетей',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все типы социальных сетей',
            component: NetworkTypeList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить тип социальных сетей',
          //   component: NetworkTypeNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить тип социальных сетей',
            component: NetworkTypeEdit
          }
        ]
      },
      {
        path: '/roomtype',
        redirect: '/roomtype/list',
        name: 'Типы комнат',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все типы комнат',
            component: RoomTypeList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить тип социальных сетей',
          //   component: NetworkTypeNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить тип комнат',
            component: RoomTypeEdit
          }
        ]
      },
      {
        path: '/roomname',
        redirect: '/roomname/list',
        name: 'Наименование комнат',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все наименования комнат',
            component: RoomNameList
          },
          {
            path: 'new',
            name: 'Добавить наименование комнат',
            component: RoomNameNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить наименование комнат',
            component: RoomNameEdit
          }
        ]
      },
      {
        path: '/report/list',
        name: 'Отчёт по квартирным счётчикам',
        component: ReportList
      },
      {
        path: '/reportMeasurement/list',
        name: 'Отчёт по переданным показаниям',
        component: ReportMeasurementList
      },
      {
        path: '/reportHouse/list',
        name: 'Отчёт по общедомовым счётчикам',
        component: ReportHouseList
      },
      {
        path: '/reportMeter/list',
        name: 'Отчёт по всем счётчикам',
        component: ReportMeterList
      },
      {
        path: '/metertype',
        redirect: '/metertype/list',
        name: 'Типы счётчиков',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все типы счётчиков',
            component: MeterTypeList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить тип счётчиков',
          //   component: MeterTypeNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить тип счётчиков',
            component: MeterTypeEdit
          }
        ]
      },
      {
        path: '/city',
        redirect: '/city/list',
        name: 'Список городов',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все города',
            component: CityList
          },
          {
            path: 'new',
            name: 'Добавить город',
            component: CityItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить данные по городу',
            component: CityItem
          }
        ]
      },
      {
        path: '/premisetype',
        redirect: '/premisetype/list',
        name: 'Типы помещений',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все типы помещений',
            component: PremiseTypeList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить тип помещений',
          //   component: PremiseTypeNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить тип помещений',
            component: PremiseTypeEdit
          }
        ]
      },
      {
        path: '/barrier',
        redirect: '/barrier/list',
        name: 'Точки доступа',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все точки доступа',
            component: BarrierList
          },
          {
            path: 'new',
            name: 'Добавить точку доступа',
            component: BarrierItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить точку доступа',
            component: BarrierItem
          }
        ]
      },
      {
        path: '/cameras',
        redirect: '/cameras/list',
        name: 'Камеры',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все камеры',
            component: CamerasList
          },
          {
            path: 'new',
            name: 'Добавить камеру',
            component: CamerasItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить камеру',
            component: CamerasItem
          }
        ]
      },
      {
        path: '/complexinfo',
        redirect: '/complexinfo/list',
        name: 'Контакты',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все контакты',
            component: ComplexInfoList
          },
          {
            path: 'new',
            name: 'Добавить контакт',
            component: ComplexInfoNew
          },
          {
            path: 'edit/:id',
            name: 'Изменить контакт',
            component: ComplexInfoEdit
          }
        ]
      },
      {
        path: '/building',
        redirect: '/building/list',
        name: 'Дома',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все дома',
            component: BuildingList
          },
          {
            path: 'new',
            name: 'Добавить дом',
            component: BuildingItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить дом',
            component: BuildingItem
          }
        ]
      },
      {
        path: '/premise',
        redirect: '/premise/list',
        name: 'Квартиры',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все квартиры',
            component: PremiseList
          },
          {
            path: 'new',
            name: 'Добавить квартиру',
            component: PremiseItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить квартиру',
            component: PremiseItem
          }
        ]
      },
      {
        path: '/chats',
        redirect: '/chats/list',
        name: 'Чаты',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все чаты',
            component: ChatList
          },
          {
            path: 'new',
            name: 'Добавить чат',
            component: ChatItem
          },
          {
            path: 'edit/:id',
            name: 'Изменить чат',
            component: ChatItem
          },
          {
            path: 'chat/:id',
            name: 'Чат',
            component: ChatView
          },
          {
            path: 'userlist/:id',
            name: 'Пользователи в чате',
            component: ChatUserList
          }
        ]
      },
      {
        path: '/chattype',
        redirect: '/chattype/list',
        name: 'Типы чатов',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'list',
            name: 'Все типы чатов',
            component: ChatTypeList
          },
          // {
          //   path: 'new',
          //   name: 'Добавить тип чатов',
          //   component: ChatTypeNew
          // },
          {
            path: 'edit/:id',
            name: 'Изменить тип чатов',
            component: ChatTypeEdit
          }
        ]
      },
      {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
          render (c) { return c('router-view') }//
        },
        children: [
          {
            path: 'colors',
            name: 'Colors',
            component: Colors
          },
          {
            path: 'typography',
            name: 'Typography',
            component: Typography
          }
        ]
      },
      {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'breadcrumbs',
            name: 'Breadcrumbs',
            component: Breadcrumbs
          },
          {
            path: 'cards',
            name: 'Cards',
            component: Cards
          },
          {
            path: 'carousels',
            name: 'Carousels',
            component: Carousels
          },
          {
            path: 'collapses',
            name: 'Collapses',
            component: Collapses
          },
          {
            path: 'jumbotrons',
            name: 'Jumbotrons',
            component: Jumbotrons
          },
          {
            path: 'list-groups',
            name: 'List Groups',
            component: ListGroups
          },
          {
            path: 'navs',
            name: 'Navs',
            component: Navs
          },
          {
            path: 'navbars',
            name: 'Navbars',
            component: Navbars
          },
          {
            path: 'paginations',
            name: 'Paginations',
            component: Paginations
          },
          {
            path: 'popovers',
            name: 'Popovers',
            component: Popovers
          },
          {
            path: 'progress-bars',
            name: 'Progress Bars',
            component: ProgressBars
          },

          {
            path: 'switches',
            name: 'Switches',
            component: Switches
          },
          {
            path: 'tooltips',
            name: 'Tooltips',
            component: Tooltips
          }
        ]
      },
      {
        path: 'buttons',
        redirect: '/buttons/buttons',
        name: 'Buttons',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'standard-buttons',
            name: 'Standard Buttons',
            component: StandardButtons
          },
          {
            path: 'button-groups',
            name: 'Button Groups',
            component: ButtonGroups
          },
          {
            path: 'dropdowns',
            name: 'Dropdowns',
            component: Dropdowns
          },
          {
            path: 'social-buttons',
            name: 'Social Buttons',
            component: SocialButtons
          }
        ]
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'editors',
        redirect: '/editors/text-editors',
        name: 'Editors',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'text-editors',
            name: 'Text Editors',
            component: TextEditors
          },
          {
            path: 'code-editors',
            name: 'Code Editors',
            component: CodeEditors
          }
        ]
      },
      {
        path: 'forms',
        redirect: '/forms/basic-forms',
        name: 'Forms',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'basic-forms',
            name: 'Basic Forms',
            component: BasicForms
          },
          {
            path: 'advanced-forms',
            name: 'Advanced Forms',
            component: AdvancedForms
          }
        ]
      },
      {
        path: 'google-maps',
        name: 'Google Maps',
        component: GoogleMaps
      },
      {
        path: 'icons',
        redirect: '/icons/font-awesome',
        name: 'Icons',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'flags',
            name: 'Flags',
            component: Flags
          },
          {
            path: 'font-awesome',
            name: 'Font Awesome',
            component: FontAwesome
          },
          {
            path: 'simple-line-icons',
            name: 'Simple Line Icons',
            component: SimpleLineIcons
          }
        ]
      },
      {
        path: 'notifications',
        redirect: '/notifications/alerts',
        name: 'Notifications',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'alerts',
            name: 'Alerts',
            component: Alerts
          },
          {
            path: 'badges',
            name: 'Badges',
            component: Badges
          },
          {
            path: 'modals',
            name: 'Modals',
            component: Modals
          },
          {
            path: 'toastr',
            name: 'Toastr',
            component: Toastr
          }
        ]
      },
      {
        path: 'tables',
        name: 'Tables',
        component: Tables
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {
    path: '/requests',
    redirect: '/requests/list',
    name: 'requests',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'list',
        name: 'Все заявки',
        component: ServiceRequestsContractorList
      },
      {
        path: 'edit/:id',
        name: 'requestsEdit',
        component: ServiceRequestsContractorEdit
      },
      {
        path: 'chat/:id',
        name: 'requestsChat',
        component: ServiceRequestsContractorChat
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'auth',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'login/:id',
        name: 'Login',
        component: Login
      },
      {
        path: 'contractor',
        name: 'contractorLogin',
        component: LoginContractor
      },
      {
        path: 'contractor/:id',
        name: 'contractorLogin',
        component: LoginContractor
      }
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: Page404
      },
      {
        path: '403',
        name: 'Page403',
        component: Page403
      },
      {
        path: '500',
        name: 'Page500',
        component: Page500
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      }
    ]
  }
]